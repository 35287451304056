import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import Handyman from '@mui/icons-material/Handyman'

const products = [
  'Konesaumatut peltikatot',
  'Kattoremontit',
  'Rakennuspellitykset',
  'Kattoturvatuotteet',
  'Sadevesijärjestelmät'
]

export default function Products () {
  return (
    <List dense disablePadding subheader={<Typography variant='h6' fontWeight='bold'>Yli 20 vuoden henkilökohtaisella kokemuksella</Typography>}>
      {products.map((p, i) => (
        <ListItem key={i}>
          <ListItemButton>
            <ListItemIcon><Handyman fontSize='small' /></ListItemIcon>
            <ListItemText disableTypography><Typography variant='h6' fontWeight='bold'>{p}</Typography></ListItemText>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}
