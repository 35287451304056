import { green, grey } from '@mui/material/colors'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Stack } from '@mui/material'

const theme = createTheme({
  palette: {
    primary: {
      main: green[500]
    },
    secondary: {
      main: grey[100]
    }
  },
  typography: {
    body1: {
      textShadow: '0 0 5px white'
    },
    body2: {
      textShadow: '0 0 5px white'
    },
    fontFamily: 'Offside, Courier New'
  }
})

export default function Layout ({ children }) {
  return (
    <Box
      sx={{
        backgroundImage: 'url(katto.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container component='main' maxWidth='sm'>
          <Stack spacing={2}>
            <Typography variant='h2' textAlign='center'>tinmar oy</Typography>
            {children}
          </Stack>
        </Container>
        <Box
          component='footer'
          sx={{
            py: 3,
            mt: 'auto'
          }}
        >
          <Container maxWidth='sm'>
            <Typography variant='body2' color='text.secondary'>
              © <Link color='inherit' href='https://tietopalvelu.ytj.fi/yritystiedot.aspx?yavain=2894255&tarkiste=130DE3417616BC960658708745D1B287637EC50D'>tinmar oy</Link> käyttää <Link color='inherit' href='https://marketingplatform.google.com/about/analytics/terms/fi/'>Analytics</Link>:iä palvelun parantamiseen.
            </Typography>
          </Container>
        </Box>
      </ThemeProvider>
    </Box>
  )
}
