import { Link, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'

export default function ReliablePartner () {
  return (
    <Grid container>
      <Grid xs={12} sm={5}>
        <img alt='luotettava kumppani' src='luotettava.png' width='211' height='99' />
      </Grid>
      <Grid xs={12} sm={7}>
        <Typography variant='body2'>
          Luotettava kumppani ilmaisee, että yritys on hoitanut tilaajavastuulain edellyttämät velvoitteet.
          Ajantasaiset tiedot voi hakea maksutta osoitteesta <Link color='secondary' href='https://zeckit.com/selvitys/FI/3090456-1?lang=fi'>zeckit.com</Link>
        </Typography>
      </Grid>
    </Grid>
  )
}
