import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import Layout from './Layout'
import reportWebVitals from './reportWebVitals'
import { analytics } from './firebase'
import { logEvent } from 'firebase/analytics'
import BusinessCard from './BusinessCard'
import Products from './Products'
import ReliablePartner from './ReliablePartner'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <StrictMode>
    <Layout>
      <Products />
      <BusinessCard />
      <ReliablePartner />
    </Layout>
  </StrictMode>
)

function sendToAnalytics ({ id, name, value }) {
  logEvent(analytics, 'web_vitals', {
    eventAction: name,
    eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    eventLabel: id, // id unique to current page load
    nonInteraction: true // avoids affecting bounce rate
  })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals(sendToAnalytics)
