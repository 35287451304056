import Email from '@mui/icons-material/Email'
import Phone from '@mui/icons-material/Phone'
import Roofing from '@mui/icons-material/Roofing'
import { Avatar, Card, CardContent, CardHeader, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'

const phone = '044 922 6258'
const email = 'markus@tinmar.fi'
const contacts = [
  {
    icon: <Phone />,
    link: `tel:+358${phone.substring(1).replace(' ', '')}`,
    text: phone
  },
  {
    icon: <Email />,
    link: `mailto:${email}`,
    text: email
  }
]
const bgPrimary = { bgcolor: t => t.palette.primary.main }

export default function BusinessCard () {
  return (
    <Card
      sx={{
        backgroundColor: 'gray',
        backgroundImage: 'url(katolla.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '350px' // '63%'
      }}
    >
      <CardHeader
        sx={bgPrimary}
        avatar={<Roofing fontSize='large' />}
        title='Markus Papinkivi'
        subheader='Rakennuspeltiseppämestari'
      />
      <CardContent>
        <Grid container>
          <Grid xsOffset={3} xs={9} smOffset={6} sm={6}>
            <List dense disablePadding>
              {contacts.map((c, i) => (
                <ListItem key={i}>
                  <ListItemButton href={c.link}>
                    <ListItemIcon>
                      <Avatar sx={bgPrimary}>{c.icon}</Avatar>
                    </ListItemIcon>
                    <ListItemText>
                      <Typography color='secondary'>{c.text}</Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
