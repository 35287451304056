import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyDXCGUdE-4OvfG7sTVM199tAGo6NRqs6LQ',
  authDomain: 'tinmar-oy.firebaseapp.com',
  projectId: 'tinmar-oy',
  storageBucket: 'tinmar-oy.appspot.com',
  messagingSenderId: '344283133171',
  appId: '1:344283133171:web:9c0ee19b2fea04e719f386',
  measurementId: 'G-FM0QL7WDFN'
}

const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
